import { ApolloError } from '@apollo/client'
import { StatusText } from './StatusText'
import styled from '@emotion/styled'
import { CssProp } from '../../typescript/emotion'
import { LazyLoadingSpinner } from '../components/LazyLoadingSpinner'
import * as React from 'react'
import { theme } from '../../styles/theme'
import { useIsomorphicIsMounted } from '../functionality/EnvironmentSpecificSsrComponent'

export type QueryStatusIndicatorProps = {
  loading?: boolean
  error?: string | ApolloError | undefined | boolean
  cssProp?: CssProp
  noResultDisplay?: React.ReactNode
  omitNoResultsDefaultStyling?: boolean
}

const TopMargin = styled.div`
  margin-top: 100px;
  width: 100%;
  text-align: center;
`

export const NoResultsContainer = styled.div`
  border: 1px solid ${theme.colors.grayLightGray};
  border-radius: 4px;
  padding: 66.5px 20px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  color: ${theme.colors.grayWarmGray};
  background: ${theme.colors.grayLightLightGray};

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
/**
 * Helper component to show loading, error, and content if no results come back for the page. Pass 'hasData' prop in to
 * prevent the indicator showing when data exists, e.g., on refetch, etc.
 */
export function QueryStatusIndicator({
  loading,
  error,
  cssProp,
  noResultDisplay = null,
  omitNoResultsDefaultStyling,
}: QueryStatusIndicatorProps) {
  // TODO revisit this if we want a better SSR version of loading/error content (shouldn't really ever matter other than
  // layout shift. we don't want error/no content indexed probably anyway).
  // there is one render pass on initial load with ssr where the client considers the call as finished from the server
  // and refetch has not yet happened on the client, which causes loading to be false for a single tick before the query
  // runs, and this leads to a little flicker of the noResultDisplay when it shouldn't show. to prevent this, only show
  // no results after we are on client and two ticks have gone by. after two ticks, if loading is actually falsy, that
  // means we actually have completed the query and no results are around, so show the no results display.

  const [loadingShouldHaveOccurred, setLoadingShouldHaveOccurred] = React.useState(false)

  const isMounted = useIsomorphicIsMounted()

  React.useEffect(() => {
    if (isMounted) setLoadingShouldHaveOccurred(true)
  }, [isMounted])
  return !loadingShouldHaveOccurred ? null : loading ? (
    <TopMargin css={cssProp}>
      <LazyLoadingSpinner />
    </TopMargin>
  ) : error ? (
    <TopMargin css={cssProp}>
      <StatusText>{typeof error === 'string' ? error : 'An error occurred.'}</StatusText>
    </TopMargin>
  ) : noResultDisplay ? (
    omitNoResultsDefaultStyling ? (
      <>{noResultDisplay}</>
    ) : (
      <NoResultsContainer>{noResultDisplay}</NoResultsContainer>
    )
  ) : null
}
