import { theme } from '../../../styles/theme'

export const focusOutlineStyles = /* css */ `
    outline: 0 solid rgba(255,255,255, 0);
    border-color: rgba(255,255,255, 0);
    box-shadow: 0 0 5px 1px ${theme.colors.cerulean};
`
const errorOutlineStyles = /* css */ `
    outline: 0 solid rgba(255,255,255, 0);
    border-color: rgba(255,255,255, 0);
    box-shadow: 0 0 5px 1px ${theme.colors.outOfRange};
`

// TODO figure out a cleaner way to do this. looking to follow native behavior (props get passed to underlying css`` call
// if it exists) while also allowing the user to call this inside styles to add overrides
// This seems to work, but double check
// https://emotion.sh/docs/styled [Composing Dynamic Styles]

/**
 * Focus outline css string (or function if focus needs additional styling) to put in a styled component. this does not need to be called in a tagged template. just use if you don't want an override. only call it with a string if you want to add additional styling to the focus
 */
export const focusOutline = (propsOrFocusStyles: any) => /* css */ `
  :focus {
   ${focusOutlineStyles}
   ${typeof propsOrFocusStyles === 'string' && propsOrFocusStyles};
  }
  transition: box-shadow .3s;
`

export const errorOutline = (propsOrFocusStyles: any) => /* css */ `
  :not(:focus) {
    ${errorOutlineStyles}
    ${typeof propsOrFocusStyles === 'string' && propsOrFocusStyles};
  }
  transition: box-shadow .3s;
`
