import * as React from 'react'
import styled from '@emotion/styled'
import { useCarousel } from '../../../../common/page-components/Carousel/useCarousel'
import { EmblaCarousel, EmblaContainer } from '../../../../common/page-components/Carousel/CarouselComponents'
import { UnstyledButton } from '../../../../common/style/UnstyledButton'
import { transientOptions } from '../../../../common/style/transientOptions'
import { theme } from '../../../../styles/theme'
import { NavigationArrow } from '../../../../assets/components/NavigationArrow'
import type { HomePageCarouselProps } from './HomePageCarousel'
import { NextLink } from '../../../../common/functionality/NextLink'
import { LEGACY_NextImage } from '../../../../common/functionality/LEGACY_NextImage'
import { LazyLoadingSpinner } from '../../../../common/components/LazyLoadingSpinner'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 32px 0;
`

const FooterControlsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`
const FooterControls = styled.div``
const FooterControl = styled(UnstyledButton, transientOptions)<{ $isActive: boolean }>`
  background-color: ${(p) => (p.$isActive ? theme.colors.grayMidnight : theme.colors.graySilver)};
  height: 2px;
  width: 13.3px;

  transition: background-color 0.3s;
  :first-of-type {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }

  :last-of-type {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }
  :not(:first-of-type) {
    margin-left: 4px;
  }
`

const Name = styled.div`
  color: ${theme.colors.grayMidnight};
  font-size: 18px;
  font-weight: 700;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
`

const SlideSectionBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0 0;
`
const NonMemberPrice = styled.div`
  margin-top: 2px;
  color: ${theme.colors.grayCoolGray};
  font-size: 13px;
  font-weight: 500;
`

const MemberPrice = styled.div`
  color: ${theme.colors.grayCoolGray};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
`

const slideWidth = (1024 - 32 * 2 - 8 * 3) / 4

const Slide = styled(NextLink)`
  align-self: baseline;
  width: ${slideWidth}px;
  min-width: ${slideWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  align-self: stretch;
`

export function DesktopCarousel({ products, loading }: HomePageCarouselProps) {
  const { emblaApi, emblaRef, selectedIndex, totalSlides } = useCarousel({
    align: 'start',
    loop: true,
    slidesToScroll: 4,
  })
  React.useEffect(() => {
    emblaApi?.reInit()
  }, [emblaApi, loading, products])

  return (
    <Container>
      {totalSlides > 1 &&
        [1, 2].map((x) => {
          const isLeft = x === 1
          const orientation = isLeft ? 'left' : 'right'

          const translateX = isLeft ? -100 : 100
          const onClick = () => (isLeft ? emblaApi?.scrollPrev() : emblaApi?.scrollNext())
          return (
            <UnstyledButton
              key={x}
              onClick={onClick}
              style={{
                position: 'absolute',
                [isLeft ? 'left' : 'right']: -8,
                transform: `translate(${translateX}%, -50%)`,
                top: '50%',
                height: 20,
              }}
            >
              <NavigationArrow orientation={orientation} color={theme.colors.cerulean} />
            </UnstyledButton>
          )
        })}
      <EmblaCarousel ref={emblaRef}>
        <EmblaContainer
          $totalSlides={totalSlides}
          style={{
            minHeight: products.length ? 'unset' : 245.5,
            background: theme.colors.grayPorcelain,
          }}
        >
          {loading && !products.length ? (
            <LazyLoadingSpinner />
          ) : (
            products.map((x) => {
              return (
                <Slide key={x.id} href={x.link}>
                  <LEGACY_NextImage
                    width={128}
                    height={128}
                    src={x.image.url}
                    alt={x.image.alternativeText ?? x.name}
                  />
                  <Name>{x.name}</Name>
                  <SlideSectionBottom>
                    <NonMemberPrice>{x.prices.freeMember.display} Retail</NonMemberPrice>
                    <MemberPrice>{x.prices.paidMember.display} Member</MemberPrice>
                  </SlideSectionBottom>
                </Slide>
              )
            })
          )}
        </EmblaContainer>
      </EmblaCarousel>
      <FooterControlsContainer>
        <FooterControls>
          {totalSlides > 1 &&
            Array.from({ length: totalSlides }, (_, i) => (
              <FooterControl key={i} $isActive={selectedIndex === i} onClick={() => emblaApi?.scrollTo(i)} />
            ))}
        </FooterControls>
      </FooterControlsContainer>
    </Container>
  )
}
