import * as React from 'react'
import { gql } from '@apollo/client'
import { useGetUserGoalIdsQuery } from '../../../generated/graphql'
import { useCookies } from '../../../hooks/useCookies'
import { userHasGoalsCookieName } from '../../../common/constants/cookies'
import { addYears } from 'date-fns'

gql`
  query GetUserGoalIds {
    get_user_guidance {
      goals {
        id
      }
    }
  }
`

export function useUserHasGoals() {
  const { data, loading } = useGetUserGoalIdsQuery({ fetchPolicy: 'network-only' })

  const dataReady = !loading && !!data

  const { set, remove } = useCookies()

  return React.useMemo(() => {
    const userHasGoals = (data?.get_user_guidance?.goals?.length ?? 0) > 0
    if (dataReady) {
      // add a cookie to track any of this so we avoid a flash on the home page when we are determing to show the goals
      // vs no goals UI
      if (userHasGoals) {
        set(userHasGoalsCookieName, 'true', { expires: addYears(new Date(), 1) })
      } else {
        remove(userHasGoalsCookieName)
      }
    }

    return { userHasGoals, dataReady }
  }, [data, dataReady, set, remove])
}
