import styled from '@emotion/styled'
import { transientOptions } from '../../../../../common/style/transientOptions'
import { theme } from '../../../../../styles/theme'
import type { CardColors } from '../utils/getPackageColor'
import { Styles, getCssProp } from '../../../../../utilities/getCssProp'

const Container = styled('div', transientOptions)<{ $textColor: string; $isGrayBg: boolean }>`
  display: flex;
  padding: 2px 6px;
  height: 17px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  color: ${(p) => p.$textColor};
  border-radius: 20px;
  background: ${(p) => (p.$isGrayBg ? theme.colors.grayLightSilver : theme.colors.grayPorcelain)};
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  width: fit-content;
`
export const getMarkerCountDisplay = (packageName: string) => {
  if (/basics/i.test(packageName)) return '40+'
  if (/core/i.test(packageName)) return '50+'
  if (/foundation/i.test(packageName)) return '60+'
  if (/comprehensive (male|female)/i.test(packageName)) return '65+'
  if (/comprehensive health$/i.test(packageName)) return '80+'
  if (/advanced (male|female)/i.test(packageName)) return '85+'
  if (/advanced longevity$/i.test(packageName)) return '100+'
}

type Props = React.PropsWithChildren<{ colors: CardColors; className?: string; packageName: string; styles?: Styles }>

export function MarkerCountPill({ colors, packageName, styles }: Props) {
  const cssProp = getCssProp(styles)
  return (
    <Container $textColor={colors.text} $isGrayBg={colors.background === theme.colors.grayPorcelain} css={cssProp}>
      {getMarkerCountDisplay(packageName)} markers
    </Container>
  )
}
