import * as React from 'react'
import { theme } from '../../styles/theme'
import styled from '@emotion/styled'
import { focusOutline } from './styles/outlineStyles'
import { transientOptions } from '../style/transientOptions'
import { LazyLoadingSpinner } from '../components/LazyLoadingSpinner'
import { ButtonBase } from '../components/ButtonBase'
import { getCssProp, Styles } from '../../utilities/getCssProp'
import { css } from '@emotion/react'
import { NextLink } from '../functionality/NextLink'

export const deleteActionStyles = css`
  color: ${theme.colors.outOfRange};
  background: ${theme.colors.grayPorcelain};
`

export const blackButtonBackground = css`
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%),
    ${theme.colors.grayCoal};
  background-blend-mode: soft-light, normal;
`

const grayButtonBackground = css`
  background: ${theme.colors.lightCloud};
  border: 1px solid ${theme.colors.lightCloud};
  box-shadow: 0px 2px 10px rgba(1, 20, 98, 0.2);
`

type SubmitProps = {
  $loading?: boolean
  $isFullWidth?: boolean
  disabled?: boolean
  $showDisabledStyles?: boolean
  $isCentered?: boolean
  $isGray?: boolean
  $isLightBlue?: boolean
  $isShort?: boolean
  $isWhite?: boolean
  $isRed?: boolean
  $isMediumHeight?: boolean
}

// TODO Add hover states for non-white button style
const Submit = styled(ButtonBase, transientOptions)<SubmitProps>`
  white-space: nowrap;
  font-weight: ${(p) => (p.$isGray ? 600 : 700)};
  font-size: ${(p) => (p.$isShort ? 13 : 15)}px;
  line-height: ${(p) => (p.$isShort ? 17 : 20)}px;
  height: ${(p) => (p.$isShort ? 24 : p.$isMediumHeight ? 30 : 40)}px;
  padding: 0 40px;
  color: ${(p) =>
    p.$loading
      ? theme.colors.grayMediumGray
      : p.$isGray
      ? theme.colors.grayCoolGray
      : p.$isWhite || p.$isLightBlue
      ? theme.colors.grayMidnight
      : theme.colors.grayPorcelain};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) =>
    p.$isGray
      ? grayButtonBackground
      : p.$isWhite
      ? `background:${theme.colors.grayPorcelain}; 
         
        :hover:not(:disabled) {
          background-color: rgba(0, 0, 0, 0.05);
        }   `
      : p.$isRed
      ? `background:${theme.colors.outOfRange};`
      : p.$isLightBlue
      ? `
      border: 1px solid ${theme.colors.cloud};
background: ${theme.colors.lightCloud};

      `
      : blackButtonBackground}
  box-shadow: 0px 2px 10px 0px rgba(1, 20, 98, 0.20);
  border-radius: 20px;
  width: ${(p) => (p.$isFullWidth ? '100%' : 'auto')};
  margin: ${(p) => (p.$isCentered ? '0 auto' : '0')};
  cursor: ${(p) => (p.$loading ? 'wait' : p.disabled ? 'not-allowed' : 'pointer')};

  :disabled {
    background: ${theme.colors.grayMediumGray};
  }
  ${(p) => (p.$showDisabledStyles ? `background:${theme.colors.grayMediumGray};` : '')}

  ${focusOutline}
  transition: all 0.22s ease-out;

  ${(p) =>
    p.$isLightBlue
      ? `
    
      :hover:not(:disabled) {
        opacity:1;
        border: 1px solid ${theme.colors.cloud};
        background: ${theme.colors.darkCloud};
      }

  `
      : ''}
`

export type SubmitButtonProps = React.PropsWithChildren<{
  showDisabledStyles?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  isGray?: boolean
  styles?: Styles
  isFullWidth?: boolean
  isCentered?: boolean
  loading?: boolean
  isShort?: boolean
  isWhite?: boolean
  isRed?: boolean
  isLightBlue?: boolean
  isMediumHeight?: boolean
  withComponent?: WithComponent
}> &
  React.ButtonHTMLAttributes<HTMLButtonElement>

type LoadingContainerProps = {
  $loading?: boolean
}

const LoadingContainer = styled('div', transientOptions)<LoadingContainerProps>`
  position: absolute;
  opacity: ${(p) => (p.$loading ? 1 : 0)};
  transition: opacity 0.15s;
`

// TODO actually get this going with proper type inferece such that we can use the SubmitButton component and turn it
// into a link simply by passing in an href
export const LinkWithButtonStyles = Submit.withComponent(NextLink)

type WithComponent = Parameters<(typeof Submit)['withComponent']>[0]

const RefUnforwardedSubmitButton = (
  {
    isCentered,
    showDisabledStyles,
    disabled,
    style,
    styles,
    children,
    isFullWidth,
    isShort,
    loading,
    isGray,
    isWhite,
    isRed,
    isLightBlue,
    isMediumHeight,
    withComponent,
    ...props
  }: SubmitButtonProps,

  ref?: React.ForwardedRef<HTMLButtonElement>
) => {
  const cssProp = getCssProp(styles)

  // !!NOTE: it seems like the ref is not being forwarded properly when using withComponent. the component does seem to
  // eventually get it, but calling things like focus seem to fail oddly. thus, do not use a forwarded ref and the
  // withComponent prop
  const Final = withComponent ? Submit.withComponent(withComponent) : Submit
  return (
    <Final
      type={'submit'}
      disabled={loading || disabled}
      $showDisabledStyles={showDisabledStyles}
      style={style}
      css={cssProp}
      $isShort={isShort}
      $isCentered={isCentered}
      $isFullWidth={isFullWidth}
      $isGray={isGray}
      $loading={loading}
      $isWhite={isWhite}
      $isLightBlue={isLightBlue}
      $isRed={isRed}
      $isMediumHeight={isMediumHeight}
      ref={ref}
      {...props}
    >
      {children}
      <LoadingContainer $loading={loading}>
        {loading && (
          <LazyLoadingSpinner
            isSmall
            delay={0}
            styles={{
              ...((isShort || isMediumHeight) && {
                height: isShort ? 20 : 25,
              }),
            }}
          />
        )}
      </LoadingContainer>
    </Final>
  )
}
export const SubmitButton = React.forwardRef(RefUnforwardedSubmitButton)
