import * as React from 'react'
import styled from '@emotion/styled'
import { UnstyledButton } from '../../../../../common/style/UnstyledButton'
import { transientOptions } from '../../../../../common/style/transientOptions'
import { theme } from '../../../../../styles/theme'
import { useAddPackageCtaProps } from '../hooks/useAddPackageCtaProps'
import { useShoppingCart } from '../../../hooks/useShoppingCart/useShoppingCart'
import { LazyLoadingSpinner } from '../../../../../common/components/LazyLoadingSpinner'
import { type CardColors } from '../utils/getPackageColor'
import { NextLink } from '../../../../../common/functionality/NextLink'
import { Styles, getCssProp } from '../../../../../utilities/getCssProp'
import {
  makeRouteFromDynamicSegmentSignature,
  shopLabsPackageDynamicSignature,
} from '../../../../../common/constants/routes'
import { analytics } from '../../../../analytics/analytics'
import { ClickEvent } from '../../../../../typescript/utility'

const MainCta = styled(UnstyledButton, transientOptions)<{ $isLoading: boolean; $colors: CardColors }>`
  width: fill-available;
  position: relative;
  border-radius: 4px;
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: ${theme.colors.grayPorcelain};
  background-color: ${(p) => p.$colors.text};
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  transition:
    background-color 0.3s,
    opacity 0.3s;

  cursor: ${(p) => (p.$isLoading ? 'progress' : 'pointer')};
  :disabled {
    cursor: ${(p) => (p.$isLoading ? 'progress' : 'not-allowed')};
    opacity: 0.8;
  }
  :hover:not(:disabled) {
    opacity: 0.8;
  }
`

const ViewDetailsButton = styled(UnstyledButton)<{ $colors: CardColors }>`
  padding: 10px 8px;
  color: ${(p) => p.$colors.text};
  text-align: center;
  margin-bottom: 4px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  transition: background-color 0.3s;

  :hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`
const ViewDetailsLink = styled(ViewDetailsButton, transientOptions)``.withComponent(NextLink)

type Props = {
  productId: string
  slug: string
  name: string
  ctaDefaultText?: string
  colors: CardColors
  styles?: { mainButton?: Styles; viewDetails?: Styles }
  nonLinkViewDetailsOnClick?: () => void
  viewDetailsChildren?: React.ReactNode
}

export function PlanCardButtons({
  productId,
  slug,
  name,
  colors,
  styles,
  ctaDefaultText,
  viewDetailsChildren = 'View Details',
  nonLinkViewDetailsOnClick,
}: Props) {
  const mainButtonCssProp = getCssProp(styles?.mainButton)
  const viewDetailsCssProp = getCssProp(styles?.viewDetails)
  const { loading, inFlightChangesProductIds } = useShoppingCart()

  const productRequestLoading = inFlightChangesProductIds.some((x) => x === productId)

  const { onClick, children, inCart, gtmDataLayerVariableDataAttributes } = useAddPackageCtaProps(
    productId && slug && name ? { productId, slug, name } : null
  )

  const pdpUrl = makeRouteFromDynamicSegmentSignature(shopLabsPackageDynamicSignature, slug)

  const viewDetailsSharedProps = { css: viewDetailsCssProp, $colors: colors, children: viewDetailsChildren }
  return (
    <>
      <MainCta
        css={mainButtonCssProp}
        $isLoading={productRequestLoading}
        disabled={loading || productRequestLoading}
        $colors={colors}
        {...gtmDataLayerVariableDataAttributes}
        onClick={async () => {
          onClick()
        }}
      >
        {productRequestLoading && <LazyLoadingSpinner isSmall styles={{ position: 'absolute' }} />}
        {!inCart && ctaDefaultText ? ctaDefaultText : children}
      </MainCta>
      {nonLinkViewDetailsOnClick ? (
        <ViewDetailsButton {...viewDetailsSharedProps} onClick={nonLinkViewDetailsOnClick} />
      ) : (
        <ViewDetailsLink
          href={pdpUrl}
          {...viewDetailsSharedProps}
          onClick={(e: ClickEvent<HTMLElement>) => {
            e.stopPropagation()
            analytics.track(`User viewed details for the ${name} Lab Package`)
          }}
        />
      )}
    </>
  )
}
