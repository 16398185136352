import { theme } from '../../../../../styles/theme'

const basicCardColor = { text: theme.colors.grayCoolGray, background: theme.colors.grayLightLightGray }

export type CardColors = ReturnType<typeof getPackageColor>
const cardColors = [
  basicCardColor,
  { text: theme.colors.dataRange02GreatText, background: theme.colors.dataRange02GreatBackground },
  {
    text: theme.colors.dataRange01ExcellentText,
    background: theme.colors.dataRange01ExcellentBackground,
  },
  { text: theme.colors.aegean, background: theme.colors.cloud },
]

export const getPackageColor = (name: string, i: number = 0) => {
  const color = /foundation/i.test(name)
    ? cardColors[1]
    : /comprehensive/i.test(name)
      ? cardColors[2]
      : /advanced/i.test(name)
        ? cardColors[3]
        : cardColors[i]

  const res = color ?? basicCardColor
  const textHexValue =
    typeof window !== 'undefined'
      ? getComputedStyle(document.documentElement)
          .getPropertyValue(res.text.replace('var(', '').replace(')', ''))
          .trim()
      : ''

  // .08 alpha is 8% opacity, equivalent to adding '14' to the end of the hex code, 1F is 20%
  // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4

  const hover = `${textHexValue}14`
  const secondaryHover = `${textHexValue}1F`
  return { ...res, hover, secondaryHover }
}
