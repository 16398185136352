import { Display } from '../../../../common/style/Display'
import type { HomePageCarouselProduct } from '../../pages/BiohackerHomePage'
import { DesktopCarousel } from './DesktopCarousel'
import { MobileCarousel } from './MobileCarousel'

export type HomePageCarouselProps = { products: HomePageCarouselProduct[]; loading: boolean }

export function HomePageCarousel({ products, loading }: HomePageCarouselProps) {
  return (
    <>
      <Display isTabletUp>
        <DesktopCarousel products={products} loading={loading} />
      </Display>
      <Display isTabletDown>
        <MobileCarousel products={products} loading={loading} />
      </Display>
    </>
  )
}
