import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { NextLink } from '../../../common/functionality/NextLink'
import { shopSupplementsRoute } from '../../../common/constants/routes'
import { BlueButton } from '../../../common/form-components/BlueButton'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { HomePageCarousel } from '../molecules/HomePageCarousel/HomePageCarousel'
import { useShopPageSections } from '../../shop/pages/ShopPage'
import { enum_stripe_product_type_enum } from '../../../generated/graphql'
import { HomePageFeaturedPackagesSection } from '../organisms/metrics/HomePageFeaturedPackagesSection'
import { useUserHasGoals } from '../../recommendations/hooks/useUserHasGoals'
import { useCookies } from '../../../hooks/useCookies'
import { userHasGoalsCookieName } from '../../../common/constants/cookies'
import dynamic from 'next/dynamic'
import { useUserIsPaidMember } from '../hooks/useUserIsPaidMember'
import { useAccount } from '../../auth/hooks/useAccount'
import { useFeatureFlag } from '../../auth/hooks/useFeatureFlag'
import { featureFlags } from '../../auth/FeatureFlagProvider'

const LazyExistingMemberProductSubBanner = dynamic(() =>
  import('../atoms/ExistingMemberProductSubBanner').then((x) => x.ExistingMemberProductSubBanner)
)
const LazyConferencePromotionAnnouncement = dynamic(() =>
  import('../atoms/ConferencePromotionAnnouncement').then((x) => x.ConferencePromotionAnnouncement)
)
const LazyHomePageBannerUserWithGoals = dynamic(() =>
  import('../atoms/HomePageBannerUserWithGoals').then((x) => x.HomePageBannerUserWithGoals)
)
const LazyHomePageBannerUserWithoutGoals = dynamic(() =>
  import('../atoms/HomePageBannerUserWithoutGoals').then((x) => x.HomePageBannerUserWithoutGoals)
)

const LazyDataSourceTilesSection = dynamic(() =>
  import('../organisms/HomePageDataTiles/HomePageDataTiles').then((x) => x.HomePageDataTiles)
)

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.grayLightLightGray};
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tabletDownMq} {
    padding: 0 16px;
  }
`

const ContentBox = styled.div`
  width: 100%;
  margin-top: 32px;
  max-width: ${theme.spacing.standardPageMaxContentWidth};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tabletDownMq} {
    margin-top: 16px;
    padding: 8px 0;
  }
`

const SectionHeaderContainer = styled.div`
  text-align: center;

  ${tabletDownMq} {
    padding: 0 16px;
  }
`

const SectionHeaderText = styled.div`
  color: ${theme.colors.grayMidnight};
  font-size: 36px;
  font-weight: 300;
`

const SectionSubheaderText = styled.div`
  margin-top: 4px;
  color: ${theme.colors.grayCoolGray};
  text-align: center;
  font-family: 'HK Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const CarouselContainer = styled.div`
  margin: 16px 16px 0;
  width: 100%;
  border-radius: 12px;
  background: ${theme.colors.grayPorcelain};
`

const BottomSectionContentBox = styled(ContentBox)`
  padding: 24px 0;
`

const ContentBoxAdditionalPaddingForCarouselArrows = styled.div`
  position: relative;
  padding: 0 28px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${tabletDownMq} {
    padding: 0;
  }
`

export type HomePageCarouselProduct = ReturnType<typeof useShopPageSections>['sections'][number]['products'][number]

export function BiohackerHomePage() {
  const { loading, sections } = useShopPageSections()

  const { isAuthenticated } = useAccount()
  const supplements = React.useMemo(
    () =>
      sections.flatMap((section) =>
        section.products.filter((product) => product.product_type === enum_stripe_product_type_enum.SUPPLEMENT)
      ),
    [sections]
  )

  const { userHasGoals } = useUserHasGoals()

  const userIsPaidMember = useUserIsPaidMember()

  const { cookies } = useCookies()

  const showSurveyCompletedUi = userHasGoals || cookies[userHasGoalsCookieName] === 'true'

  const upgradeHomePageDataTilesIsEnabled = useFeatureFlag(featureFlags.upgradeHomePageDataTiles).isEnabled
  return (
    <Container>
      {showSurveyCompletedUi ? <LazyHomePageBannerUserWithGoals /> : <LazyHomePageBannerUserWithoutGoals />}
      {userIsPaidMember ? <LazyExistingMemberProductSubBanner /> : <LazyConferencePromotionAnnouncement />}
      {isAuthenticated && upgradeHomePageDataTilesIsEnabled && <LazyDataSourceTilesSection />}
      <Section>
        <ContentBoxAdditionalPaddingForCarouselArrows>
          <ContentBox>
            <SectionHeaderContainer>
              <SectionHeaderText>Supplements</SectionHeaderText>
              <SectionSubheaderText>
                Curated premium supplements shipped directly from the manufacturer.
              </SectionSubheaderText>
            </SectionHeaderContainer>
            <CarouselContainer>
              <HomePageCarousel products={supplements} loading={loading} />
            </CarouselContainer>
            <NextLink href={shopSupplementsRoute} style={{ marginTop: 16 }}>
              <BlueButton style={{ minWidth: 117 }}>Shop All</BlueButton>
            </NextLink>
          </ContentBox>
        </ContentBoxAdditionalPaddingForCarouselArrows>
      </Section>
      <Section>
        <BottomSectionContentBox>
          <SectionHeaderContainer>
            <SectionHeaderText>Lab Tests</SectionHeaderText>
            <SectionSubheaderText>
              Directly order expertly tailored lab testing packages without a doctor visit.
            </SectionSubheaderText>
          </SectionHeaderContainer>
          <HomePageFeaturedPackagesSection />
        </BottomSectionContentBox>
      </Section>
    </Container>
  )
}
