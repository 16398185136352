import * as React from 'react'
import { gql } from '@apollo/client'
import { enum_stripe_product_type_enum, useProductTypeDefaultImageQuery } from '../../../generated/graphql'

gql`
  query ProductTypeDefaultImage {
    cms {
      productTypeDefaultImage {
        data {
          attributes {
            FEE {
              ...ImageAttributes
            }
            LAB_PANEL {
              ...ImageAttributes
            }
            LAB_TEST {
              ...ImageAttributes
            }
            MEMBERSHIP {
              ...ImageAttributes
            }
            PACKAGE {
              ...ImageAttributes
            }
            PRESCRIPTION {
              ...ImageAttributes
            }
            PRODUCT {
              ...ImageAttributes
            }
            SUPPLEMENT {
              ...ImageAttributes
            }
          }
        }
      }
    }
  }
`

const getImageData = (entry?: { data?: { attributes?: { url: string } } }) => entry?.data?.attributes?.url ?? ''

export function useProductDefaultImages() {
  const { data, loading } = useProductTypeDefaultImageQuery({ fetchPolicy: 'cache-first' })
  const productDefaultImages = React.useMemo(() => {
    const base = data?.cms?.productTypeDefaultImage?.data?.attributes ?? {}
    return {
      [enum_stripe_product_type_enum.FEE]: getImageData(base.FEE),
      [enum_stripe_product_type_enum.LAB_PANEL]: getImageData(base.LAB_PANEL),
      [enum_stripe_product_type_enum.LAB_TEST]: getImageData(base.LAB_TEST),
      [enum_stripe_product_type_enum.MEMBERSHIP]: getImageData(base.MEMBERSHIP),
      [enum_stripe_product_type_enum.PACKAGE]: getImageData(base.PACKAGE),
      [enum_stripe_product_type_enum.PRESCRIPTION]: getImageData(base.PRESCRIPTION),
      [enum_stripe_product_type_enum.PRODUCT]: getImageData(base.PRODUCT),
      [enum_stripe_product_type_enum.SUPPLEMENT]: getImageData(base.SUPPLEMENT),
    } as Record<enum_stripe_product_type_enum, string>
  }, [data])

  return { loading, productDefaultImages }
}
