import { theme } from '../../styles/theme'
import { ButtonBase } from '../components/ButtonBase'
import styled from '@emotion/styled'
import { LazyLoadingSpinner } from '../components/LazyLoadingSpinner'

// TODO this should probably be the same component as SubmitButton. need to compare what the differences are fully. if
// its mostly just color and border radius, definitely consolidate into a single component

export const blueButtonStyles = /* css */ `
  position: relative;
  background-color: ${theme.colors.cloud};
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  color: ${theme.colors.cerulean};
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s background-color;
  line-height: normal;

  :hover:not(:disabled) {
    opacity: 1;
    background-color: ${theme.colors.darkCloud};
  }

`

const Button = styled(ButtonBase)`
  ${blueButtonStyles}
`

export const BlueButton = ({
  children,
  loading,
  ...props
}: Omit<Parameters<typeof Button>[0], '$loading'> & { loading?: boolean }) => {
  return (
    <Button $loading={loading} {...props}>
      {loading && <LazyLoadingSpinner isSmall styles={{ position: 'absolute', top: 0 }} />}
      {children}
    </Button>
  )
}
