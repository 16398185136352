import * as React from 'react'
import { useCookies } from '../../../hooks/useCookies'
import { useAccount } from '../../auth/hooks/useAccount'
import { enum_membership_tier_enum } from '../../../generated/graphql'
import { userIsPaidMemberCookieName } from '../../../common/constants/cookies'
import { addMonths } from 'date-fns'

export function useUserIsPaidMember() {
  const { user, isLoading } = useAccount()
  const userIsPaidMember = user.membershipTier === enum_membership_tier_enum.PAID_MEMBER
  const { set, remove, cookies } = useCookies()

  const userIsPaidMemberCookie = cookies[userIsPaidMemberCookieName] === 'true'
  React.useEffect(() => {
    if (isLoading) return
    if (userIsPaidMember) {
      // assume they will be a member for the next month, and whenever that isn't the case,we'll update the cookie
      set(userIsPaidMemberCookieName, 'true', { expires: addMonths(new Date(), 1) })
    } else {
      remove(userIsPaidMemberCookieName)
    }
  }, [userIsPaidMember, isLoading, set, remove])

  return isLoading ? userIsPaidMemberCookie : userIsPaidMember
}
