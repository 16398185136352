import styled from '@emotion/styled'
import { Styles } from '../utilities/getCssProp'
import { CssSize, getCssSizeString } from '../utilities/getCssSizeString'
import { Orientation } from './hooks/useGetShortestRotationFromOrientation'
import { transientOptions } from '../common/style/transientOptions'

export type BaseSvgProps = React.SVGProps<SVGSVGElement>
export interface BaseSvgComponentProps extends BaseSvgProps {
  color?: string
  size?: CssSize
  styles?: Styles
}

export interface RotatableSvgComponentProps extends BaseSvgComponentProps {
  orientation?: Orientation
}

type DimensionsProps = { $size: string | number } | { width: string | number; height: string | number }

type StyledSvgBaseProps = { $rotation?: number } & DimensionsProps

export const SvgBase = styled('svg', transientOptions)<StyledSvgBaseProps>`
  rotate: ${(p) => p.$rotation ?? 0}deg;
  transition: all 0.3s;
  min-width: ${(p) => {
    const relevantItem = 'width' in p ? p.width : p.$size
    return getCssSizeString(relevantItem)
  }};
  min-height: ${(p) => {
    const relevantItem = 'height' in p ? p.height : p.$size
    return getCssSizeString(relevantItem)
  }};
  max-width: ${(p) => {
    const relevantItem = 'width' in p ? p.width : p.$size
    return getCssSizeString(relevantItem)
  }};
  max-height: ${(p) => {
    const relevantItem = 'height' in p ? p.height : p.$size
    return getCssSizeString(relevantItem)
  }};
`
