import Head from 'next/head'
import { useRouter } from 'next/router'
import { originServerUrl } from '../constants/envDependentRoutes'

type ProductData = {
  images: string[]
  price: string /* dollars and cents */
  brand?: string
}

type Props = { title: string; description: string; image: string; productData?: ProductData }

function stripHtml(input: string) {
  return input.replace(/<[^>]*>/g, '').replace(/(\r\n|\n|\r)/gm, '')
}
export function HtmlHead({
  title: potentiallyRichTextTitle,
  description: potentiallyRichTextDesciption,
  image,
  productData,
}: Props) {
  const title = stripHtml(potentiallyRichTextTitle)
  const description = stripHtml(potentiallyRichTextDesciption)
  const { asPath } = useRouter()
  const url = `${originServerUrl}${asPath}`

  // SKU in this and the meta product tags?
  const productJsx = productData ? (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: /* json */ `
          {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": "${title}",
            "description": "${description}",
            "image": ${JSON.stringify(productData.images)},
            ${
              productData.brand
                ? `"brand": {
              "@type": "Brand",
              "name": "${productData.brand}"
            },`
                : ''
            }
            "offers": {
              "@type": "Offer",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition",
              "price": "${productData.price}",
              "priceCurrency": "USD",
              "url": "${url}"
            }
    }`,
        }}
        key="product-script-meta"
      />
      <meta property="product:price.amount" content={`${productData.price}`} />
      <meta property="product:price.currency" content="USD" />
      {productData.brand && <meta property="product:brand" content={productData.brand} />}
      <meta property="product:availability" content="in stock" />
    </>
  ) : null

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta name="og:title" property="og:title" content={title} key="og:title" />
      <meta name="og:description" property="og:description" content={description} key="og:description" />
      <meta name="og:type" property="og:type" content={productData ? 'product' : 'website'} key="og:type" />
      <meta name="og:url" property="og:url" content={url} key="og:url" />
      <meta name="og:image" property="og:image" content={image} key="og:image" />
      <meta name="twitter:description" content={description} key="twitter:description" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta name="twitter:image" content={image} key="twitter:image" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href={url} key="canonical" />
      {productJsx}
    </Head>
  )
}
